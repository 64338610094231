<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" class="upload-demo" drag multiple list-type="picture"
        :file-list="fileList" :auto-upload="false" action="" :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :limit="limitCount"
        :show-file-list="showFileList" :on-picedit="handlePicEdit"
        :on-checkbox="handleCheckbox" accept="image/jpeg,image/png,image/bmp" :activeTab="activeTab">
      </Upload>

      <div v-if="fileList.length > 0 && !ocrInProgress && !ocrSuccess" class="checkall-wrap">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }"
        @click.stop="handleExport(1)">{{ btnTxt }}
      </div>

      <div class="radio-wrap">
        <el-radio-group v-model="radio" @input="handleRadioChange" :disabled="!fileList.length">
          <el-radio-button label="正序"></el-radio-button>
          <el-radio-button label="倒序"></el-radio-button>
          <el-radio-button label="清空"></el-radio-button>
        </el-radio-group>

        <FilePos :fileList="fileList"></FilePos>
      </div>
    </div>

    <ImageEditModal v-if="showModal" :show="showModal" :imageUrl="imageSrc" @close="closeModal"
      @cropped="handleCroppedImage" />
    <PopImgSaveType ref="popImgSaveType" v-if="popImgSaveTypeVisible" :fileList="fileList"></PopImgSaveType>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from '../upload';
import UploadTip from './../common/UploadTip';
import FilePos from './../common/FilePos';
import ImageEditModal from './../ImageEditModal.vue';
import PopImgSaveType from './../common/PopImgSaveType.vue';

const OSS = require('ali-oss');
const MAX_IMAGE = 100;

export default {
  components: {
    Upload,
    UploadTip,
    ImageEditModal,
    FilePos,
    PopImgSaveType
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "图片拼接",
      dialogVisible: false,
      popImgSaveTypeVisible: false,
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [],
      files:[],
      mainTaskIdList: [],
      showModal: false,
      imageSrc: '',
      cropperFile: null,
      radio: '正序',
      pdf2ImagesIng: false, //pdf转图片中
      isIndeterminate: false,
      checkAll: false,
    };
  },
  methods: {
    isLoggendIn() {
      return this.$cookies.get('token') != null;
    },
    isNoDayUseCount() {
      console.log("isNoDayUseCount", this.$H.userInfo)
      if (this.$H.userInfo && this.$H.userInfo.vipLevel == 0 && this.$H.userInfo.dayUseCount == 0) {
        console.log("isNoDayUseCount true")
        return true;
      }

      console.log("isNoDayUseCount false")
      return false;
    },
    checkUserVip() {
      if (!this.isLoggendIn()) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (this.isNoDayUseCount()) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }  

      return true;
    },
    handleExport(type) {
      console.log("handleExport");
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      this.showPopImgSaveType(type);
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/bmp'];
      if (file.raw && !acceptedImageTypes.includes(file.raw.type)) {
        this.$message.error('请选择图片文件');
        this.fileList = [];
        return false;
      }

      var done = () => {
        this.fileList = fileList;
        let page = 1;
        this.fileList.map(file => {
          if (file.checked == undefined) {     
            file.checked = true;
          }

          file.page = page;
          page++;
        });
        
        this.handleCheckbox();
      };

      if (this.ocrSuccess || this.ocrInProgress) {
        this.fileList = fileList.splice(0, fileList.length - 1);
        this.$confirm('上传新文档需清空当前文档，是否继续？')
          .then(()=> {
            this.initOcr();
            done();
          })
          .catch(()=> { });
          return;
      }

      done();
    },
    handleRemove(file, fileList) {
      console.log("handleRemove");
      this.fileList = fileList;

      if (fileList.length == 0) {
        this.initOcr();
      }
    },
    handleExceed(files, fileList) {
      console.log("handleExceed files", files, typeof(files));
      if (files.length > this.limitCount) {
        this.$message.warning(`最多只能选择${this.limitCount}个文件`);
      }

      if (this.ocrSuccess) {
        this.$message.error('请先清空再上传！');
      }
    },
    initOcr() {
      this.limitCount = MAX_IMAGE;
      this.ocrSuccess = false;
      this.ocrInProgress = false;
      this.fileList = [];
    },
    handleResetOCR() {
      this.initOcr();
      this.$nextTick(() => {
        this.radio = '正序';
      });
      
    },
    handlePicEdit(file) {
      console.log("handlePicEdit file", file);
      this.openModal(file);
    },
    handleRadioChange(radio) {
      console.log("handleRadioChange", radio)
      if (radio == '正序' || radio == '倒序') {
        if (this.fileList && this.fileList.length > 0)
          this.fileList.reverse();
      } else if (radio == '清空') {
        setTimeout(()=>{
          this.handleResetOCR();
        }, 500);
      }
    },
    handleCheckbox() {
      console.log("UploadArea handleCheckbox");
      let checkedCount = 0;
      this.fileList.forEach(file => {
        if (file.checked) {
          checkedCount++;
        }
      });

      this.checkAll = checkedCount == this.fileList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileList.length;
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      this.fileList.forEach(file => {
        file.checked = val;
      });
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    showPopImgSaveType(type) {
      this.popImgSaveTypeVisible = true;
      this.$nextTick(() => {
        this.$refs.popImgSaveType.init(type);
      });
    },
    openModal(file) {
      this.showModal = true;
      this.imageSrc = file.srcUrl || file.url;
      this.cropperFile = file;
    },
    closeModal() {
      this.showModal = false;
    },
    handleCroppedImage(croppedImage) {
      console.log('裁剪后的图片:', croppedImage);
      // 你可以在这里上传裁剪后的图片到 OSS 或其他存储
      if (!this.cropperFile.srcUrl) {
        this.cropperFile.srcUrl = this.cropperFile.url;
        this.cropperFile.srcRaw = this.cropperFile.raw;
      }
      this.cropperFile.url = croppedImage;
      this.cropperFile.raw = this.dataURItoBlob(croppedImage);
      Vue.set(this.fileList, this.fileList.indexOf(this.cropperFile), this.cropperFile);
      this.closeModal();
    }
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';
</style>