var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-area"},[_c('div',{staticClass:"upload-demo upload-wrap"},[_c('Upload',{ref:"uploadRef",staticClass:"upload-demo",attrs:{"drag":"","multiple":"","list-type":"picture","file-list":_vm.fileList,"auto-upload":false,"action":"","on-change":_vm.handleChange,"limit":_vm.limitCount,"show-file-list":false,"accept":"application/pdf","activeTab":_vm.activeTab}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.fileList.length>0),expression:"formData.fileList.length>0"}],staticClass:"table-wrap"},[_c('el-form',{ref:"fileListForm",staticStyle:{"width":"100%"},attrs:{"model":_vm.formData,"label-width":"0px"}},[_c('el-table',{key:_vm.tableKey,ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.formData.fileList,"tooltip-effect":"dark"}},[_c('el-table-column',{attrs:{"type":"selection","width":_vm.columnWidth[0]}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_vm._v("全选")])])])],2),_c('el-table-column',{attrs:{"label":"名称","width":_vm.columnWidth[1]},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"name-txt"},[_vm._v(" "+_vm._s(scope.row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"page","label":"页数","width":_vm.columnWidth[2]}}),_c('el-table-column',{attrs:{"label":"输出范围","width":_vm.columnWidth[3]},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"range-wrap"},[_c('el-form-item',{attrs:{"prop":`fileList.${scope.$index}.rangeStart`,"rules":[{
                  ..._vm.rules.rangeStart,
                  row: scope.row,
                }]}},[_c('el-input',{staticClass:"input-small",attrs:{"placeholder":"","type":"tel"},model:{value:(scope.row.rangeStart),callback:function ($$v) {_vm.$set(scope.row, "rangeStart", $$v)},expression:"scope.row.rangeStart"}})],1),_c('div',{staticClass:"input-split"}),_c('el-form-item',{attrs:{"prop":`fileList.${scope.$index}.rangeEnd`,"rules":[{
                  ..._vm.rules.rangeEnd,
                  row: scope.row,
                }]}},[_c('el-input',{staticClass:"input-small",attrs:{"autosize":"","placeholder":"","type":"tel"},model:{value:(scope.row.rangeEnd),callback:function ($$v) {_vm.$set(scope.row, "rangeEnd", $$v)},expression:"scope.row.rangeEnd"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":_vm.columnWidth[4]},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(scope.$index, _vm.fileList)}}},[_c('div',{staticClass:"icon-delete"},[_c('i',{staticClass:"el-icon-delete"})])])]}}])})],1)],1)],1),_c('UploadTip',{attrs:{"show":_vm.fileList.length == 0,"activeTab":_vm.activeTab}}),_c('div',{staticClass:"btn-ocr",class:{ 'btn-ocr-active': _vm.fileList.length > 0 && !_vm.ocrInProgress },on:{"click":function($event){$event.stopPropagation();return _vm.handleStartOCR.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.btnTxt)+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }