import OSS from 'ali-oss';

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
  }

  return fmt;
}

export async function getOssAuth() {
  try {
    const res = await this.$api.getOssAuth({}); // 替换为你的 API 地址
    if (res.data.code == 0) {
      return res.data.data;
    } else {
      throw new Error('获取 OSS 授权失败');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// 上传文件到阿里云 OSS
export async function uploadFile(ossAuth, file, fileSuffix) {
  const client = new OSS({
    // yourRegion填写Bucket所在地域。Region填写为oss-cn-hangzhou。
    region: ossAuth.region,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: ossAuth.accessKeyId,
    accessKeySecret: ossAuth.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: ossAuth.securityToken,
    // 填写Bucket名称。
    bucket: ossAuth.bucket
  });

  try {
    var date = new Date();
    const randomNum = Math.floor(Math.random() * 100000);
    const dir = `upload/${date.format("yyyyMMdd")}/${date.getTime()}-${randomNum}${fileSuffix}`;
    console.log('文件上传路径', dir);

    const result = await client.put(dir, file.raw);
    console.log('文件上传成功', result);
    file.fileUrl = result.url;
  } catch (error) {
    console.error('文件上传失败', error);
  }
}