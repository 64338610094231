<template>
  <div id="app">
    <Sidebar ref="sidebar" @tab-selected="handleTabSelection" :activeTab="activeTab" />
    <div class="main-content">
      <Pdf2Word v-show="activeTab == 'Pdf2Word'" :activeTab="activeTab" tabName="Pdf2Word" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'Pdf2Txt'" :activeTab="activeTab" tabName="Pdf2Txt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'Pdf2Excel'" :activeTab="activeTab" tabName="Pdf2Excel"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Img v-show="activeTab == 'Pdf2Img'" :activeTab="activeTab" tabName="Pdf2Img" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />

      <Img2Word v-show="activeTab == 'Img2Word'" :activeTab="activeTab" tabName="Img2Word" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Word v-show="activeTab == 'Img2Txt'" :activeTab="activeTab" tabName="Img2Txt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <!-- <Img2Word v-show="activeTab == 'Img2Excel'" :activeTab="activeTab" tabName="Img2Excel"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" /> -->
      <Img2Pdf v-show="activeTab == 'Img2Pdf'" :activeTab="activeTab" tabName="Img2Pdf" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />

      <PdfMerger v-show="activeTab == 'PdfMerger'" :activeTab="activeTab" tabName="PdfMerger"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />
      <PdfSplit v-show="activeTab == 'PdfSplit'" :activeTab="activeTab" tabName="PdfSplit" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <PdfZip v-show="activeTab == 'PdfZip'" :activeTab="activeTab" tabName="PdfZip" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'PdfExTxt'" :activeTab="activeTab" tabName="PdfExTxt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'PdfExTable'" :activeTab="activeTab" tabName="PdfExTable"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />

      <ImgSplice v-show="activeTab == 'ImgSplice'" :activeTab="activeTab" tabName="ImgSplice"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />
      <ImgZip v-show="activeTab == 'ImgZip'" :activeTab="activeTab" tabName="ImgZip" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Word v-show="activeTab == 'ImgExTxt'" :activeTab="activeTab" tabName="ImgExTxt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <!-- <Img2Word v-show="activeTab == 'ImgExTable'" :activeTab="activeTab" tabName="ImgExTable"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" /> -->
    </div>
    <Footer @feedback="handleFeedback" />

    <Feedback ref="feedback" v-if="feedbackVisible"></Feedback>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import Pdf2Word from './components/Pdf/Pdf2Word.vue'
import Pdf2Img from './components/Pdf/Pdf2Img.vue'
import Img2Word from './components/Img/Img2Word.vue'
import Img2Pdf from './components/Img/Img2Pdf.vue'
import PdfMerger from './components/Pdf/PdfMerger.vue'
import PdfSplit from './components/Pdf/PdfSplit.vue'
import PdfZip from './components/Pdf/PdfZip.vue'
import ImgSplice from './components/Img/ImgSplice.vue'
import ImgZip from './components/Img/ImgZip.vue'
import Footer from './components/common/Footer.vue'
import Feedback from './components/Feedback.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    Pdf2Word,
    Pdf2Img,
    Img2Word,
    Img2Pdf,
    PdfMerger,
    PdfSplit,
    PdfZip,
    ImgSplice,
    ImgZip,
    Footer,
    Feedback
  },
  data() {
    return {
      activeTab: 'Pdf2Word', // 初始状态
      feedbackVisible: true,
    };
  },
  mounted() {
    // console.log("App mounted")
    localStorage.clear();
  },
  methods: {
    handleTabSelection(selectedTab) {
      console.log('Selected tab:', selectedTab);
      console.log(window.screen.height, window.screen.width)
      this.activeTab = selectedTab;
    },
    handleNotification(notification) {
      console.log('Received notification:', notification);
      if (notification.type == 'login') {
        this.$refs.sidebar.showTipLogin();
      } else if (notification.type == 'userinfo') {
        this.$refs.sidebar.loginSuccess();
      } else if (notification.type == 'purchase') {
        this.$refs.sidebar.showTipPurchase();
      } 
    },
    handleFeedback() {
      console.log("handleFeedback", this.$refs.feedback)
      this.feedbackVisible = true
      this.$nextTick(() => {
        this.$refs.feedback.init();
      })
    },
  },
}
</script>

<style>
#app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 30px 40px 0px;
}
</style>