<template>
  <transition-group tag="ul" :class="[
      'el-upload-list',
      'el-upload-list--' + listType,
      { 'is-disabled': disabled }
    ]" name="el-list">
    <li v-for="file in files" :id="`item-${file.page}`"
      :class="['el-upload-list__item', 'is-' + file.status, focusing ? 'focusing' : '']" :key="file.uid" tabindex="0"
      @keydown.delete="!disabled && $emit('remove', file)" @focus="focusing = true" @blur="focusing = false"
      @click="focusing = false">
      <slot :file="file">
        <el-checkbox-group v-if="!file.ocrSuccess && !file.ocrInProgress && !ocrInProgress && !ocrSuccess"
          v-model="file.checked" @change="handleCheckedChange(file)">
          <el-checkbox :key="file.uid"></el-checkbox>
        </el-checkbox-group>
        <img class="el-upload-list__item-thumbnail"
          v-if="file.status !== 'uploading' && ['picture-card', 'picture'].indexOf(listType) > -1" :src="file.url"
          alt="">
        <div v-if="file.ocrSuccess" class="ocr-wrap" @click="handleClick(file)">
          <div v-if="file.errorCode=='OK'" class="mask">
            <div class="loader-tips">识别完成</div>
            <div class="loader-tips">点击查看结果</div>
          </div>
          <div v-else class="failed mask">
            <div
              v-if="activeTab === 'Pdf2Excel' || activeTab === 'Img2Excel' || activeTab === 'PdfExTable' || activeTab === 'ImgExTable' "
              class="loader-tips">未包含表格<br>不可转换</div>
            <div v-else-if="activeTab === 'PdfExTxt' || activeTab === 'ImgExTxt'" class="loader-tips">未包含文字<br>不可转换
            </div>
            <div v-else class="loader-tips">识别失败</div>
          </div>

          <div class="ocr-btn">
            <div class="loader-tips" @click.stop="handleReOcr(file)">重新识别</div>
          </div>
        </div>
        <div v-else class="ocr-wrap" :class="{ 'ocr-wrap-border': file.selected }" @click="handleClick(file)">
          <div v-show=" file.ocrInProgress" class="mask">
            <div class="loader-tips">识别中...</div>
          </div>
          <div class="ocr-btn">
            <div class="loader-tips ocr-btn-w" @click.stop="handleEdit(file)">编辑</div>
            <div v-if="activeTab !== 'Pdf2Img' && activeTab !== 'Img2Pdf' && activeTab !== 'ImgSplice'" class="ocr-btn-right">
              <div class="ocr-btn-line"></div>
              <div class="loader-tips ocr-btn-w" @click.stop="handleOcr(file)">识别</div>
            </div>

          </div>
        </div>

        <div class="page-txt">{{ file.page }}</div>

        <a class="el-upload-list__item-name" @click="handleClick(file)">
          <i class="el-icon-document"></i>{{file.name}}
        </a>
        <label class="el-upload-list__item-status-label">
          <i :class="{
            'el-icon-upload-success': true,
            'el-icon-circle-check': listType === 'text',
            'el-icon-check': ['picture-card', 'picture'].indexOf(listType) > -1
          }"></i>
        </label>
        <i class="el-icon-close" v-if="!disabled" @click="$emit('remove', file)"></i>
        <i class="el-icon-close-tip" v-if="!disabled">{{ t('el.upload.deleteTip') }}</i>
        <!--因为close按钮只在li:focus的时候 display, li blur后就不存在了，所以键盘导航时永远无法 focus到 close按钮上-->
        <el-progress v-if="file.status === 'uploading'" :type="listType === 'picture-card' ? 'circle' : 'line'"
          :stroke-width="listType === 'picture-card' ? 6 : 2" :percentage="parsePercentage(file.percentage)">
        </el-progress>
        <span class="el-upload-list__item-actions" v-if="listType === 'picture-card'">
          <span class="el-upload-list__item-preview" v-if="handlePreview && listType === 'picture-card'"
            @click="handlePreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="$emit('remove', file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </slot>
    </li>
  </transition-group>
</template>
<script>
  import Vue from 'vue';
  import Locale from 'element-ui/src/mixins/locale';
  import ElProgress from 'element-ui/packages/progress';

  export default {

    name: 'ElUploadList',

    mixins: [Locale],

    data() {
      return {
        focusing: false,
        ocrInProgress: false,
        ocrSuccess: false,
      };
    },
    components: { ElProgress },
    props: {
      files: {
        type: Array,
        default() {
          return [];
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      handlePreview: Function,
      handlePicOcr: Function,
      handlePicEdit: Function,
      handleCheckbox: Function,
      listType: String,
      activeTab: String,
    },
    watch: {
      files(nVal) {
        if (nVal && nVal.length === 0) {
          this.ocrInProgress = false;
          this.ocrSuccess = false;
          return;
        }

        if (nVal && nVal.length > 0) {
          for (let i = 0; i < nVal.length; i++) {
            if (nVal[i].ocrInProgress) {
              this.ocrInProgress = true;
              break;
            }

            if (nVal[i].ocrSuccess) {
              this.ocrSuccess = true;
              break;
            }
          }
        }
      },
      activeTab(nVal) {
        console.log("upload list watch activeTab", nVal);
        this.tab = nVal;
      }
    },
    methods: {
      parsePercentage(val) {
        return parseInt(val, 10);
      },
      handleClick(file) {
        this.handlePreview && this.handlePreview(file);
      },
      handleOcr(file) {
        // console.log("handleOcr")
        if (file.ocrInProgress) return;

        this.handlePicOcr && this.handlePicOcr(file);
      },
      handleEdit(file) {
        if (file.ocrInProgress) return;

        this.handlePicEdit && this.handlePicEdit(file);
      },
      handleReOcr(file) {
        console.log("handleReOcr")
        file.ocrSuccess = false;
        file.resultUrl = '';
        Vue.set(this.files, this.files.indexOf(file), file);
      },
      handleCheckedChange(file) {
        console.log("handleCheckedChange", file, this.files);

        this.handleCheckbox && this.handleCheckbox(file);
        Vue.set(this.files, this.files.indexOf(file), file);
      },
    }
  };
</script>

<style>
.ocr-wrap{  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ocr-wrap-border {
  border: 1px solid #0769F6;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.failed {
  background: rgba(228, 126, 126, 0.6)
}

.transparent {
  background: transparent;
}

.ocr-btn {
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 29px;
  background: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.ocr-btn-right {
  display: flex;
  align-items: center;
  flex: 1;
}

.ocr-btn-line {
  width: 2px;
  height: 10px;
  background: #fff;
}

.ocr-btn-w {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-tips {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    font-style: normal;
}

.page-txt {
  position: absolute;
  bottom: -30px;
  left: 54px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    text-align: center;
    font-style: normal;
}
</style>