<template>
  <div class="sidebar-collapses">
    <el-collapse v-model="activeNames" @change="handleChange" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <div class="collapse-title-wrap" @mouseenter="isHovered1 = true" @mouseleave="isHovered1 = false">
            <div class="collapse-title" :class="{ 'collapse-title-active': activeNames.includes('1') }">PDF转其他</div>
            <img class="icon-img"
              :src="isHovered1 ? require('@/assets/img/icon-minus.png') : (activeNames.includes('1') ? require('@/assets/img/icon-minus.png') : require('@/assets/img/icon-plus.png'))"
              alt="" :style="{
              transform: activeNames.includes('1') ? 'rotate(135deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease'
            }" />
          </div>
        </template>
        <div class="tab-content">
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Pdf2Word' }"
            @click="handleTabClick('Pdf2Word')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Word.png" alt="">
            PDF转WORD
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Pdf2Txt' }" @click="handleTabClick('Pdf2Txt')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Txt.png" alt="">
            PDF转文字
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Pdf2Excel' }"
            @click="handleTabClick('Pdf2Excel')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Excel.png" alt="">
            PDF转EXCEL
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Pdf2Img' }" @click="handleTabClick('Pdf2Img')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Img.png" alt="">
            PDF转图片
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <div class="collapse-title-wrap" @mouseenter="isHovered2 = true" @mouseleave="isHovered2 = false">
            <div class="collapse-title" :class="{ 'collapse-title-active': activeNames.includes('2') }">图片转其他</div>
            <img
              :src="isHovered2 ? require('@/assets/img/icon-minus.png') : (activeNames.includes('2') ? require('@/assets/img/icon-minus.png') : require('@/assets/img/icon-plus.png'))"
              alt="" :style="{
                transform: activeNames.includes('2') ? 'rotate(135deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease'
              }" />
          </div>
        </template>
        <div class="tab-content">
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Img2Word' }"
            @click="handleTabClick('Img2Word')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Word.png" alt="">
            图片转WORD
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Img2Txt' }" @click="handleTabClick('Img2Txt')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Txt.png" alt="">
            图片转文字
          </div>
          <!-- <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Img2Excel' }"
            @click="handleTabClick('Img2Excel')">
            <img class="tab-icon" src="@/assets/img/icon/Pdf2Excel.png" alt="">
            图片转EXCEL
          </div> -->
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'Img2Pdf' }" @click="handleTabClick('Img2Pdf')">
            <img class="tab-icon" src="@/assets/img/icon/Img2Pdf.png" alt="">
            图片转PDF
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item name="3">
        <template slot="title">
          <div class="collapse-title-wrap" @mouseenter="isHovered3 = true" @mouseleave="isHovered3 = false">
            <div class="collapse-title" :class="{ 'collapse-title-active': activeNames.includes('3') }">PDF操作</div>
            <img
              :src="isHovered3 ? require('@/assets/img/icon-minus.png') : (activeNames.includes('3') ? require('@/assets/img/icon-minus.png') : require('@/assets/img/icon-plus.png'))"
              alt="" :style="{
                transform: activeNames.includes('3') ? 'rotate(135deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease'
              }" />
          </div>
        </template>
        <div class="tab-content">
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'PdfMerger' }"
            @click="handleTabClick('PdfMerger')">
            <img class="tab-icon" src="@/assets/img/icon/PdfMerger.png" alt="">
            PDF合并
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'PdfSplit' }"
            @click="handleTabClick('PdfSplit')">
            <img class="tab-icon" src="@/assets/img/icon/PdfSplit.png" alt="">
            PDF拆分
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'PdfZip' }" @click="handleTabClick('PdfZip')">
            <img class="tab-icon" src="@/assets/img/icon/PdfZip.png" alt="">
            PDF压缩
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'PdfExTxt' }"
            @click="handleTabClick('PdfExTxt')">
            <img class="tab-icon" src="@/assets/img/icon/PdfExTxt.png" alt="">
            内容提取-文字
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'PdfExTable' }"
            @click="handleTabClick('PdfExTable')">
            <img class="tab-icon" src="@/assets/img/icon/PdfExTable.png" alt="">
            内容提取-表格
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item name="4">
        <template slot="title">
          <div class="collapse-title-wrap" @mouseenter="isHovered4 = true" @mouseleave="isHovered4 = false">
            <div class="collapse-title" :class="{ 'collapse-title-active': activeNames.includes('4') }">图片操作</div>
            <img
              :src="isHovered4 ? require('@/assets/img/icon-minus.png') : (activeNames.includes('4') ? require('@/assets/img/icon-minus.png') : require('@/assets/img/icon-plus.png'))"
              alt="" :style="{
                transform: activeNames.includes('4') ? 'rotate(135deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease'
              }" />
          </div>
        </template>

        <div class="tab-content">
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'ImgSplice' }"
            @click="handleTabClick('ImgSplice')">
            <img class="tab-icon" src="@/assets/img/icon/PdfMerger.png" alt="">
            图片拼接
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'ImgZip' }" @click="handleTabClick('ImgZip')">
            <img class="tab-icon" src="@/assets/img/icon/PdfSplit.png" alt="">
            图片压缩
          </div>
          <div class="tab-item" :class="{ 'tab-item-active': curTab == 'ImgExTxt' }"
            @click="handleTabClick('ImgExTxt')">
            <img class="tab-icon" src="@/assets/img/icon/PdfExTxt.png" alt="">
            内容提取-文字
          </div>
          <!-- <div class="tab-item" :class="{ 'tab-item-active': curTab == 'ImgExTable' }"
            @click="handleTabClick('ImgExTable')">
            <img class="tab-icon" src="@/assets/img/icon/PdfExTable.png" alt="">
            内容提取-表格
          </div> -->
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>


<script>
export default {
  name: 'SidebarTabs',
  props: {
    tabSelected: Function,
  },
  data() {
    return {
      curTab: 'Pdf2Word', // 默认选中的 Tab
      activeNames: ['1'],
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHovered4: false,
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    handleTabClick(tab) {
      this.curTab = tab;
      this.$emit('tab-selected', tab);
    }
  }
}
</script>

<style scoped>

.sidebar-collapses {
  margin-left: -22px;
  margin-top: 10px;
  z-index: 11;
  /* height: 700px; */
  /* overflow-y: scroll; */
  padding-bottom: 15px;
  transition: 0.3s;
}

.sidebar-collapses::-webkit-scrollbar {
  display: none;
}

/* .sidebar-collapses::-webkit-scrollbar {
    width: 6px;
    height: 0px;
}

.sidebar-collapses:hover::-webkit-scrollbar {
  z-index: 0;
  width: 6px;
  height: 0px;
}

.sidebar-collapses::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  height: 0px;
  background: #b4bccc
}

.sidebar-collapses::-webkit-scrollbar-corner,
.sidebar-collapses::-webkit-scrollbar-track {
  background: #fff
}

.sidebar-collapses::-webkit-scrollbar-track-piece {
  background: #fff;
  width: 6px;
  height: 0px;
} */

.collapse-title-wrap {
  width: 100%;
  display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      text-align: left;
      font-style: normal;
}

.collapse-title-wrap:hover {
  color: #0067FF;
}

.collapse-title {
  
}

.collapse-title-active {
  color: #0067FF;
}

/deep/ .el-collapse-item__arrow {
  display: none;
}

/deep/ .el-collapse-item__header {
  border-bottom: none;
  margin-left: 20px;
  width: 170px;
  height: 85px;
  justify-content: center;
}

/deep/ .el-collapse-item__wrap {
  border-bottom: none;
}

/deep/ .el-collapse-item__header.is-active {
  color: #0067FF;
}

/deep/ .el-collapse-item__arrow {
      font-size: 14px;
        color: #414141;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}

.tab-content {
  /* padding-bottom: 20px; */
  cursor: pointer;
}

.tab-item {
  cursor: pointer;
  position: relative;
  width: 170px;
    padding: 10px 0;
  padding-left: 20px;
  

  display: flex;
  align-items: center;

  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

.tab-item:hover {
  color: #0067FF;
}

.tab-item-active {
  background: #F2F2F2;
  /* width: 168px; */
}

.tab-item-active::after {
  position: absolute;
  content: " ";
  left: 0;
  width: 2px;
  height: 42px;
  background: #0067FF;
}

.tab-icon {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.icon-img {
  width: 12px;
  height: 12px;
}

.icon-img:hover {
  content: url('@/assets/img/icon-minus.png');
}
</style>