<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="title">请选择输出模式</div>

      <div class="type-wrap">
        <div class="img-type" :class="{ 'img-type-active': txtType === 0}" @click="handleSelect(0)">TXT</div>
        <div class="img-type" :class="{ 'img-type-active': txtType === 1 }" @click="handleSelect(1)">WORD</div>
      </div>

      <div class="btn-do-login" @click="handleClick">合并下载</div>
    </div>
  </el-dialog>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const MAX_SINGLE_IMG_COUNT = 20;

export default {
  props: {
    fileList: Array,
  },
  data() {
    return {
      visible: false,
      txtType: 0,//0:TXT  1:WORD
    }
  },
  methods: {
    init() {
      this.visible = true;
      this.txtType = 0;
      this.$nextTick(() => {
      })
    },
    handleSelect(txtType) {
      this.txtType = txtType;
    },
    handleClick() {
      this.$emit('mergeDown', this.txtType);
      this.visible = false;
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}

/deep/ .el-dialog__body {
  padding: 0;
  height: 480px;
}

.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.title {
  margin-top: 100px;
  margin-bottom: 60px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    text-align: center;
    font-style: normal;
}

.type-wrap {
  width: 620px;
  display: flex;
  justify-content: space-between;
}

.img-type {
  cursor: pointer;
  width: 300px;
    height: 76px;
    border: 1px solid #8A8A8A;

    font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 30px;
      color: #8A8A8A;
      line-height: 42px;
      text-align: center;
      font-style: normal;

      display: flex;
      justify-content: center;
      align-items: center;
}

.img-type-active {
  border: 1px solid #086AF6;
  color: #086AF6;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 50px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}
</style>