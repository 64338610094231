<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" class="upload-demo" drag multiple list-type="picture" :file-list="fileList"
        :auto-upload="false" action="" :on-change="handleChange" :limit="limitCount" :show-file-list="false"
        accept="application/pdf" :activeTab="activeTab">
      </Upload>

      <div v-show="formData.fileList.length>0" class="table-wrap">
        <el-form ref="fileListForm" :model="formData" label-width="0px" style="width: 100%;">
          <el-table ref="multipleTable" :data="formData.fileList" tooltip-effect="dark" style="width: 100%;"
            :key="tableKey">
            <el-table-column type="selection" :width="columnWidth[0]">
              <template slot="header">
                <div style="display: flex; align-items: center;">
                  <span>全选</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="名称" :width="columnWidth[1]">
              <template slot-scope="scope">
                <div class="name-txt"> {{ scope.row.name }} </div>
              </template>
            </el-table-column>
            <el-table-column prop="page" label="页数" :width="columnWidth[2]">
            </el-table-column>
            <el-table-column label="输出范围" :width="columnWidth[3]">
              <template slot-scope="scope">
                <div class="range-wrap">
                  <el-form-item :prop="`fileList.${scope.$index}.rangeStart`" :rules="[{
                    ...rules.rangeStart,
                    row: scope.row,
                  }]">
                    <el-input class="input-small" v-model="scope.row.rangeStart" placeholder="" type="tel"></el-input>
                  </el-form-item>
                  <div class="input-split"></div>

                  <el-form-item :prop="`fileList.${scope.$index}.rangeEnd`" :rules="[{
                    ...rules.rangeEnd,
                    row: scope.row,
                  }]">
                    <el-input class="input-small" autosize="" v-model="scope.row.rangeEnd" placeholder=""
                      type="tel"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" :width="columnWidth[4]">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.$index, fileList)" type="text" size="small">
                  <div class="icon-delete">
                    <i class="el-icon-delete"></i>
                  </div>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }"
        @click.stop="handleStartOCR">{{ btnTxt }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from '../upload';
import UploadTip from './../common/UploadTip';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { downloadFile } from '@/utils/download.js'
import { getOssAuth, uploadFile } from '@/utils/ossUpload';
import TaskProgress from '@/utils/taskProgress';

const MAX_IMAGE = 10000;

export default {
  components: {
    Upload,
    UploadTip
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      optType: 2,
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "PDF合并",
      dialogVisible: false,
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [],
      files:[],
      formData: { fileList: []},
      selectAll: false,
      rules: {
        rangeStart: {
          validator: (rule, value, callback) => {
            console.log("validateRangeStart", rule.row)
            const { rangeEnd, page } = rule.row;
            if (value < 1 || value > page || value > rangeEnd) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
        rangeEnd: {
          validator: (rule, value, callback) => {
            console.log("validateRangeEnd", rule.row, value)
            const { rangeStart, page } = rule.row;
            if (value < rangeStart || value < 1 || value > page) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        }
      }, // 表单规则
      columnWidth: [120, 500, 400, 400, 120],
      baseWidth: 1920, // 默认浏览器宽度
      baseColWidth: [120, 500, 400, 400, 120], // 默认列宽
      tableKey: 0, // 用于强制表格刷新
    };
  },
  mounted() {
    // this.fileList = Vue.observable([]);
    this.formData.fileList = this.fileList;

    window.addEventListener('resize', this.updateTableWidth);
    this.updateTableWidth();
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateTableWidth);
  },
  methods: {
    updateTableWidth() {
      const currentWidth = window.innerWidth; // 当前窗口宽度
      const scale = currentWidth / this.baseWidth; // 缩放比例
      for(let i = 0; i < this.baseColWidth.length; i++) { 
        this.columnWidth[i] = Math.floor(this.baseColWidth[i] * scale); // 动态计算列宽
      }
      
      console.log("updateTableWidth", currentWidth, scale, this.columnWidth)
      this.refreshTable();
    },
    refreshTable() {
      console.log("refreshTable")
      this.tableKey += 1; // 修改 key，强制重新渲染表格
    },
    isLoggendIn() {
      return this.$cookies.get('token') != null;
    },
    isNoDayUseCount() {
      console.log("isNoDayUseCount", this.$H.userInfo)
      const opt = this.$H.userInfo.optFreeList && this.$H.userInfo.optFreeList.find(item => item.optType === 2);
      if (this.$H.userInfo && this.$H.userInfo.vipLevel == 0 && opt && opt.freeCount == 0) {
        console.log("isNoDayUseCount true")
        return true;
      }

      console.log("isNoDayUseCount false")
      return false;
    },
    checkUserVip() {
      if (!this.isLoggendIn()) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (this.isNoDayUseCount()) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }  

      return true;
    },
    handleStartOCR() {
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      const selectedRows = this.$refs.multipleTable.selection;
      console.log("handleStartOCR", selectedRows);
      if (!this.checkPageRange(selectedRows)) {
        this.$message.error('请检查输出范围是否正确');
        return;
      }

      this.uploadToOss(selectedRows);
    },
    checkPageRange(fileList) {
      return fileList.every((file) => {
        const { page, rangeStart, rangeEnd } = file;
        return (
          rangeStart >= 1 &&
          rangeEnd >= 1 &&
          rangeStart <= page &&
          rangeEnd <= page &&
          rangeStart <= rangeEnd
        );
      });
    },
     handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      if (file && file.raw && file.raw.type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.fileList = [];
        return false;
      }

       if (file && file.size && file.size > 5 * 1024 * 1024) {//52428800
         this.$message.error('单个文件仅支持5M以内，请重新上传。');
         this.fileList = [];
         return false;
       }

      this.pdfPageCount(file, fileList);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.formData.fileList = [...this.fileList];
    },
    async uploadToOss(fileList) {
      console.log("uploadToOss fileList", fileList);
      if (fileList.length < 1) {
        this.$message.warning('请选择需要合并的文件');
        return;
      }

      this.showLoading();

      try {
        const ossAuth = await getOssAuth.call(this); // 调用封装的获取授权方法，确保上下文是 Vue 实例

        // 定义上传结果存储数组
        const uploadResults = await Promise.all(
          fileList.map(async (file) => {
            try {
              const result = await uploadFile(ossAuth, file, ".pdf");
              return { success: true, file, result }; // 成功标志
            } catch (error) {
              console.error(`文件上传失败: ${file.name}`, error);
              return { success: false, file, error }; // 失败标志
            }
          })
        );

        // 处理上传结果
        const successFiles = uploadResults.filter(item => item.success); // 成功的文件
        const failedFiles = uploadResults.filter(item => !item.success); // 失败的文件

        console.log('上传成功的文件:', successFiles);
        console.log('上传失败的文件:', failedFiles);

        if (failedFiles.length > 0) {
          this.$message.warning(`${failedFiles.length} 个文件上传失败，请重试`);
          return;
        }

        if (successFiles.length > 0) {
          const files = successFiles.map(item => ({
            fileUrl: item.file.fileUrl,
            rangeLimit: `${item.file.rangeStart}-${item.file.rangeEnd}`,
          }));

          this.startPdfMerger(files); 
        } 
      } catch (error) {
        this.hideLoading();
        this.$message.error('上传过程中发生错误:' + error);
        console.error('上传过程中发生错误:', error);
      }
    },
    async startPdfMerger(files) {
      let res = await this.$api.pdfMerge({ files: files, optType: this.optType });
      console.log("startPdfMerger", res)
      if (res.data.code === 0) {
        this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });

        await this.startTaskProgressCheck(res.data.mainTaskId);
        this.downloadFile();
        this.hideLoading();
      } else if (res.data.code === 401) {
        this.$emit('notify', { message: '未登录', type: 'login' });
        this.hideLoading();
      } else if (res.data.code === 600) {
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        this.hideLoading();
      } else {
        this.$message.error(res.data.msg);
        this.hideLoading();
      }
    },
    async startTaskProgressCheck(mainTaskId) {
      //下载resultUrl存在fileList[0]中
      const taskProgress = new TaskProgress(
        this.$api,
        this.fileList
      );

      try {
        await taskProgress.startProgressCheck(mainTaskId);
        console.log('Task completed successfully');
      } catch (error) {
        console.error('Task failed:', error);
      }
    },
    downloadFile() {
      console.log("downloadFile", this.fileList)
      if (this.fileList[0].resultUrl) {
        let mergeUrl = this.fileList[0].resultUrl;
        const fileName = mergeUrl.substring(mergeUrl.lastIndexOf('/') + 1);
        console.log(fileName);
        downloadFile(mergeUrl, fileName);
      } else {
        this.$message.error('PDF拼接下载链接为空');
      }
    },
    async pdfPageCount(file, fileList) {
      console.log("pdfPageCount", file)
      if (file && file.raw.type === 'application/pdf') {
        this.showLoading();
        const fileReader = new FileReader();
        
        fileReader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          // 设置 PDF.js worker
          pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
          // 加载 PDF 文件
          const pdfDoc = await pdfjsLib.getDocument(typedArray).promise;
          console.log("pdf2Images", pdfDoc)
          Vue.set(file, 'page', pdfDoc.numPages);
          Vue.set(file, 'rangeStart', 1);
          Vue.set(file, 'rangeEnd', pdfDoc.numPages);

          console.log("pdf2Images fileList", this.fileList)
          this.fileList.push(file);
          this.formData.fileList = [...this.fileList]; // 确保引用变动，触发重新渲染
          
          this.hideLoading();
        };

        fileReader.readAsArrayBuffer(file.raw); 
      }
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    validateRangeStart(rule, value, callback) {
      const index = this.formData.fileList.findIndex((file) => file.rangeStart === value);
      const { rangeEnd, page } = this.formData.fileList[index];
      if (value < 1 || value > page || value > rangeEnd) {
        callback(new Error(" "));
      } else {
        callback();
      }
    },
    validateRangeEnd(rule, value, callback) {
      const index = this.formData.fileList.findIndex((file) => file.rangeEnd === value);
      const { rangeStart, page } = this.formData.fileList[index];
      if (value < rangeStart || value < 1 || value > page) {
        callback(new Error(" "));
      } else {
        callback();
      }
    },
    validateField(index, field) {
      this.$refs.fileListForm.validateField(`fileList.${index}.${field}`);
    },
    handleSelectAll(val) {
      this.fileList.forEach((item) => {
        item.checked = val;
      });
    },
    handleRowChange(row) {
      this.selectAll = this.fileList.every((item) => item.checked);
    },
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';
/deep/ .el-input__inner {
  border-radius: 0;
  text-align: center;
  padding: 0 5px;
  height: 100%;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    font-style: normal;
}

/deep/ .el-table td.el-table__cell div {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    font-style: normal;
}

/deep/ .el-icon-delete {
  font-size: 22px;
  color: #2669F3;
}
</style>