<template>
  <div class="footer">
    <div class="footer-content">
      <div></div>
      <div class="info-wrap">
        <div class="beian-wrap"> {{ beiAnHao }} </div>
        <div class="wangan-wrap"> 浙公网安备33010802013692号 </div>
      </div>

      <div class="about-wrap">
        <div class="about-item" @click="handleAboutUs">关于我们</div>
        <div class="about-line">|</div>
        <div class="about-item" @click="handleFeedback">意见反馈</div>
        <div class="about-line">|</div>
        <div class="about-item" @click="handleHelp">使用教程</div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Footer',
  commponents: {
  },
  props: {
    tabSelected: Function,
  },
  data() {
    return {
      beiAnHao: '浙ICP备14015144号-7',
    };
  },
  created() {
    this.setBeiAnHao();
  },
  methods: {
    setBeiAnHao() {
      const currentUrl = window.location.href;
      console.log(currentUrl);
      if (currentUrl.includes('qxocr.cn')) {
        this.beiAnHao = '浙ICP备14015144号-8'
      }
    },
    handleAboutUs() {
      console.log("handleAboutUs")
      window.open("https://www.qizhixinxi.com#user", "_blank");
    },
    handleFeedback() {
      this.$emit("feedback");
    },
    handleHelp() {
      window.open("http://help.qxocr.com/", "_blank");
    },
  }
}
</script>

<style scoped>
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
}

.footer-content {
  padding: 0 30px;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.about-wrap {
  margin-left: 30px;
  cursor: pointer;
  position: relative;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #1E77F6;
  line-height: 22px;
  text-align: center;
  font-style: normal;

  display: flex;
  /* justify-content: space-around; */
}

.about-item {
  width: 70px;
}

.about-line {
  margin: 0 10px;
}

.info-wrap {
  display: flex;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.beian-wrap {
  margin-right: 40px;
}
</style>